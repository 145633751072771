import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PartnerBox = makeShortcode("PartnerBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Als IT-Unternehmen arbeiten wir natürlich im Verbund mit zahlreichen anderen Unternehmen der Branche zusammen. Langjährige Partnerschaften sind uns dabei ebenso wichtig, wie gute Beziehungen zu unseren Kunden. Durch Kooperationen und Mitgliedschaften in verschiedenen Verbänden sichern wir höchste Qualitätsansprüche unserer Kunden und ständige Weiterbildungen unserer Mitarbeiter ab.`}</p>
    <h2>{`Unsere Partnerschaften`}</h2>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "184px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.874999999999996%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/034dee3cfc01e16fe63b9a8170c0afc8/81b0c/logo-oracle.avif 160w", "/static/034dee3cfc01e16fe63b9a8170c0afc8/d0ae9/logo-oracle.avif 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/034dee3cfc01e16fe63b9a8170c0afc8/60a22/logo-oracle.webp 160w", "/static/034dee3cfc01e16fe63b9a8170c0afc8/e26c4/logo-oracle.webp 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/034dee3cfc01e16fe63b9a8170c0afc8/69538/logo-oracle.png 160w", "/static/034dee3cfc01e16fe63b9a8170c0afc8/5a64f/logo-oracle.png 184w"],
              "sizes": "(max-width: 184px) 100vw, 184px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/034dee3cfc01e16fe63b9a8170c0afc8/5a64f/logo-oracle.png",
              "alt": "Logo Florida-Eis Manufaktur GmbH",
              "title": "Logo Florida-Eis Manufaktur GmbH",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Oracle`}</h3>
      <p>{`Status: Silver Partner`}</p>
      <p><a parentName="p" {...{
          "href": "http://www.oracle.com/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b212ad01c776286737161382c168b6c0/81b0c/logo-florida.avif 160w", "/static/b212ad01c776286737161382c168b6c0/f13a9/logo-florida.avif 200w", "/static/b212ad01c776286737161382c168b6c0/5f1e1/logo-florida.avif 340w", "/static/b212ad01c776286737161382c168b6c0/3c285/logo-florida.avif 520w", "/static/b212ad01c776286737161382c168b6c0/56a33/logo-florida.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/b212ad01c776286737161382c168b6c0/60a22/logo-florida.webp 160w", "/static/b212ad01c776286737161382c168b6c0/ba381/logo-florida.webp 200w", "/static/b212ad01c776286737161382c168b6c0/8ae5a/logo-florida.webp 340w", "/static/b212ad01c776286737161382c168b6c0/002e6/logo-florida.webp 520w", "/static/b212ad01c776286737161382c168b6c0/e88ff/logo-florida.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b212ad01c776286737161382c168b6c0/69538/logo-florida.png 160w", "/static/b212ad01c776286737161382c168b6c0/772e8/logo-florida.png 200w", "/static/b212ad01c776286737161382c168b6c0/9f933/logo-florida.png 340w", "/static/b212ad01c776286737161382c168b6c0/69902/logo-florida.png 520w", "/static/b212ad01c776286737161382c168b6c0/0a47e/logo-florida.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b212ad01c776286737161382c168b6c0/0a47e/logo-florida.png",
              "alt": "Logo Florida-Eis Manufaktur GmbH",
              "title": "Logo Florida-Eis Manufaktur GmbH",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Florida-Eis Manufaktur GmbH`}</h3>
      <p>{`Die smarte Truhe`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.floridaeis.de//"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/89dacf4826cc288be69f00f050c4aa7c/81b0c/logo-redhat.avif 160w", "/static/89dacf4826cc288be69f00f050c4aa7c/f13a9/logo-redhat.avif 200w", "/static/89dacf4826cc288be69f00f050c4aa7c/5f1e1/logo-redhat.avif 340w", "/static/89dacf4826cc288be69f00f050c4aa7c/3c285/logo-redhat.avif 520w", "/static/89dacf4826cc288be69f00f050c4aa7c/56a33/logo-redhat.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/89dacf4826cc288be69f00f050c4aa7c/60a22/logo-redhat.webp 160w", "/static/89dacf4826cc288be69f00f050c4aa7c/ba381/logo-redhat.webp 200w", "/static/89dacf4826cc288be69f00f050c4aa7c/8ae5a/logo-redhat.webp 340w", "/static/89dacf4826cc288be69f00f050c4aa7c/002e6/logo-redhat.webp 520w", "/static/89dacf4826cc288be69f00f050c4aa7c/e88ff/logo-redhat.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/89dacf4826cc288be69f00f050c4aa7c/69538/logo-redhat.png 160w", "/static/89dacf4826cc288be69f00f050c4aa7c/772e8/logo-redhat.png 200w", "/static/89dacf4826cc288be69f00f050c4aa7c/9f933/logo-redhat.png 340w", "/static/89dacf4826cc288be69f00f050c4aa7c/69902/logo-redhat.png 520w", "/static/89dacf4826cc288be69f00f050c4aa7c/0a47e/logo-redhat.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/89dacf4826cc288be69f00f050c4aa7c/0a47e/logo-redhat.png",
              "alt": "Logo RedHat",
              "title": "Logo RedHat",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`RedHat`}</h3>
      <p>{`Status: Ready Business Partner`}</p>
      <p><a parentName="p" {...{
          "href": "http://www.redhat.com/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/dbc3afa7a27fea7cf86621b343c688a2/81b0c/logo-fujitsu.avif 160w", "/static/dbc3afa7a27fea7cf86621b343c688a2/f13a9/logo-fujitsu.avif 200w", "/static/dbc3afa7a27fea7cf86621b343c688a2/5f1e1/logo-fujitsu.avif 340w", "/static/dbc3afa7a27fea7cf86621b343c688a2/3c285/logo-fujitsu.avif 520w", "/static/dbc3afa7a27fea7cf86621b343c688a2/56a33/logo-fujitsu.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/dbc3afa7a27fea7cf86621b343c688a2/60a22/logo-fujitsu.webp 160w", "/static/dbc3afa7a27fea7cf86621b343c688a2/ba381/logo-fujitsu.webp 200w", "/static/dbc3afa7a27fea7cf86621b343c688a2/8ae5a/logo-fujitsu.webp 340w", "/static/dbc3afa7a27fea7cf86621b343c688a2/002e6/logo-fujitsu.webp 520w", "/static/dbc3afa7a27fea7cf86621b343c688a2/e88ff/logo-fujitsu.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/dbc3afa7a27fea7cf86621b343c688a2/69538/logo-fujitsu.png 160w", "/static/dbc3afa7a27fea7cf86621b343c688a2/772e8/logo-fujitsu.png 200w", "/static/dbc3afa7a27fea7cf86621b343c688a2/9f933/logo-fujitsu.png 340w", "/static/dbc3afa7a27fea7cf86621b343c688a2/69902/logo-fujitsu.png 520w", "/static/dbc3afa7a27fea7cf86621b343c688a2/0a47e/logo-fujitsu.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/dbc3afa7a27fea7cf86621b343c688a2/0a47e/logo-fujitsu.png",
              "alt": "Logo FUJITSU",
              "title": "Logo FUJITSU",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`FUJITSU`}</h3>
      <p>{`Fujitsu Technology Solutions`}</p>
      <p><a parentName="p" {...{
          "href": "http://www.fujitsu.com/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b7bb9ce72744de6dfb483ff7f5fd8663/81b0c/logo-etomer.avif 160w", "/static/b7bb9ce72744de6dfb483ff7f5fd8663/f13a9/logo-etomer.avif 200w", "/static/b7bb9ce72744de6dfb483ff7f5fd8663/e47c0/logo-etomer.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/b7bb9ce72744de6dfb483ff7f5fd8663/60a22/logo-etomer.webp 160w", "/static/b7bb9ce72744de6dfb483ff7f5fd8663/ba381/logo-etomer.webp 200w", "/static/b7bb9ce72744de6dfb483ff7f5fd8663/c85cb/logo-etomer.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b7bb9ce72744de6dfb483ff7f5fd8663/69538/logo-etomer.png 160w", "/static/b7bb9ce72744de6dfb483ff7f5fd8663/772e8/logo-etomer.png 200w", "/static/b7bb9ce72744de6dfb483ff7f5fd8663/5a46d/logo-etomer.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b7bb9ce72744de6dfb483ff7f5fd8663/5a46d/logo-etomer.png",
              "alt": "Logo etomer",
              "title": "Logo etomer",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`etomer GmbH`}</h3>
      <p><a parentName="p" {...{
          "href": "https://www.etomer.com/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a2bd5f7b748c0effedcff0484e39c725/81b0c/logo-tu-dresden.avif 160w", "/static/a2bd5f7b748c0effedcff0484e39c725/f13a9/logo-tu-dresden.avif 200w", "/static/a2bd5f7b748c0effedcff0484e39c725/5f1e1/logo-tu-dresden.avif 340w", "/static/a2bd5f7b748c0effedcff0484e39c725/3c285/logo-tu-dresden.avif 520w", "/static/a2bd5f7b748c0effedcff0484e39c725/56a33/logo-tu-dresden.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/a2bd5f7b748c0effedcff0484e39c725/60a22/logo-tu-dresden.webp 160w", "/static/a2bd5f7b748c0effedcff0484e39c725/ba381/logo-tu-dresden.webp 200w", "/static/a2bd5f7b748c0effedcff0484e39c725/8ae5a/logo-tu-dresden.webp 340w", "/static/a2bd5f7b748c0effedcff0484e39c725/002e6/logo-tu-dresden.webp 520w", "/static/a2bd5f7b748c0effedcff0484e39c725/e88ff/logo-tu-dresden.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a2bd5f7b748c0effedcff0484e39c725/69538/logo-tu-dresden.png 160w", "/static/a2bd5f7b748c0effedcff0484e39c725/772e8/logo-tu-dresden.png 200w", "/static/a2bd5f7b748c0effedcff0484e39c725/9f933/logo-tu-dresden.png 340w", "/static/a2bd5f7b748c0effedcff0484e39c725/69902/logo-tu-dresden.png 520w", "/static/a2bd5f7b748c0effedcff0484e39c725/0a47e/logo-tu-dresden.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a2bd5f7b748c0effedcff0484e39c725/0a47e/logo-tu-dresden.png",
              "alt": "Logo TU Dresden",
              "title": "Logo TU Dresden",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`TU Dresden`}</h3>
      <p><a parentName="p" {...{
          "href": "http://www.tu-dresden.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <h2>{`Unsere Mitgliedschaften`}</h2>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8683d097637c8cc3a7323b30cd20d925/81b0c/logo-asqf.avif 160w", "/static/8683d097637c8cc3a7323b30cd20d925/f13a9/logo-asqf.avif 200w", "/static/8683d097637c8cc3a7323b30cd20d925/5f1e1/logo-asqf.avif 340w", "/static/8683d097637c8cc3a7323b30cd20d925/3c285/logo-asqf.avif 520w", "/static/8683d097637c8cc3a7323b30cd20d925/56a33/logo-asqf.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/8683d097637c8cc3a7323b30cd20d925/60a22/logo-asqf.webp 160w", "/static/8683d097637c8cc3a7323b30cd20d925/ba381/logo-asqf.webp 200w", "/static/8683d097637c8cc3a7323b30cd20d925/8ae5a/logo-asqf.webp 340w", "/static/8683d097637c8cc3a7323b30cd20d925/002e6/logo-asqf.webp 520w", "/static/8683d097637c8cc3a7323b30cd20d925/e88ff/logo-asqf.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/8683d097637c8cc3a7323b30cd20d925/69538/logo-asqf.png 160w", "/static/8683d097637c8cc3a7323b30cd20d925/772e8/logo-asqf.png 200w", "/static/8683d097637c8cc3a7323b30cd20d925/9f933/logo-asqf.png 340w", "/static/8683d097637c8cc3a7323b30cd20d925/69902/logo-asqf.png 520w", "/static/8683d097637c8cc3a7323b30cd20d925/0a47e/logo-asqf.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8683d097637c8cc3a7323b30cd20d925/0a47e/logo-asqf.png",
              "alt": "Logo ASQF",
              "title": "Logo ASQF",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`ASQF`}</h3>
      <p>{`Arbeitskreis Software-Qualität und -Fortbildung e.V.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.asqf.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf8d5342b501ff202b85239c1ad93c2/81b0c/logo-bitkom.avif 160w", "/static/1cf8d5342b501ff202b85239c1ad93c2/f13a9/logo-bitkom.avif 200w", "/static/1cf8d5342b501ff202b85239c1ad93c2/5f1e1/logo-bitkom.avif 340w", "/static/1cf8d5342b501ff202b85239c1ad93c2/3c285/logo-bitkom.avif 520w", "/static/1cf8d5342b501ff202b85239c1ad93c2/56a33/logo-bitkom.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/1cf8d5342b501ff202b85239c1ad93c2/60a22/logo-bitkom.webp 160w", "/static/1cf8d5342b501ff202b85239c1ad93c2/ba381/logo-bitkom.webp 200w", "/static/1cf8d5342b501ff202b85239c1ad93c2/8ae5a/logo-bitkom.webp 340w", "/static/1cf8d5342b501ff202b85239c1ad93c2/002e6/logo-bitkom.webp 520w", "/static/1cf8d5342b501ff202b85239c1ad93c2/e88ff/logo-bitkom.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf8d5342b501ff202b85239c1ad93c2/69538/logo-bitkom.png 160w", "/static/1cf8d5342b501ff202b85239c1ad93c2/772e8/logo-bitkom.png 200w", "/static/1cf8d5342b501ff202b85239c1ad93c2/9f933/logo-bitkom.png 340w", "/static/1cf8d5342b501ff202b85239c1ad93c2/69902/logo-bitkom.png 520w", "/static/1cf8d5342b501ff202b85239c1ad93c2/0a47e/logo-bitkom.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1cf8d5342b501ff202b85239c1ad93c2/0a47e/logo-bitkom.png",
              "alt": "Logo BITKOM",
              "title": "Logo BITKOM",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`BITKOM`}</h3>
      <p>{`Bundesverband Informationswirtschaft, Telekommunikation und neue Medien e.V.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.bitkom.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4115da283cd1f2ef180538b026f3bcc6/81b0c/logo-doag.avif 160w", "/static/4115da283cd1f2ef180538b026f3bcc6/f13a9/logo-doag.avif 200w", "/static/4115da283cd1f2ef180538b026f3bcc6/5f1e1/logo-doag.avif 340w", "/static/4115da283cd1f2ef180538b026f3bcc6/3c285/logo-doag.avif 520w", "/static/4115da283cd1f2ef180538b026f3bcc6/56a33/logo-doag.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/4115da283cd1f2ef180538b026f3bcc6/60a22/logo-doag.webp 160w", "/static/4115da283cd1f2ef180538b026f3bcc6/ba381/logo-doag.webp 200w", "/static/4115da283cd1f2ef180538b026f3bcc6/8ae5a/logo-doag.webp 340w", "/static/4115da283cd1f2ef180538b026f3bcc6/002e6/logo-doag.webp 520w", "/static/4115da283cd1f2ef180538b026f3bcc6/e88ff/logo-doag.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4115da283cd1f2ef180538b026f3bcc6/69538/logo-doag.png 160w", "/static/4115da283cd1f2ef180538b026f3bcc6/772e8/logo-doag.png 200w", "/static/4115da283cd1f2ef180538b026f3bcc6/9f933/logo-doag.png 340w", "/static/4115da283cd1f2ef180538b026f3bcc6/69902/logo-doag.png 520w", "/static/4115da283cd1f2ef180538b026f3bcc6/0a47e/logo-doag.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4115da283cd1f2ef180538b026f3bcc6/0a47e/logo-doag.png",
              "alt": "Logo DOAG",
              "title": "Logo DOAG",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`DOAG`}</h3>
      <p>{`Deutsche Oracle Anwendergruppe e.V.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.doag.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0ad4d3fe6c6251318e443264c1d46189/81b0c/logo-ihk.avif 160w", "/static/0ad4d3fe6c6251318e443264c1d46189/f13a9/logo-ihk.avif 200w", "/static/0ad4d3fe6c6251318e443264c1d46189/5f1e1/logo-ihk.avif 340w", "/static/0ad4d3fe6c6251318e443264c1d46189/3c285/logo-ihk.avif 520w", "/static/0ad4d3fe6c6251318e443264c1d46189/56a33/logo-ihk.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/0ad4d3fe6c6251318e443264c1d46189/60a22/logo-ihk.webp 160w", "/static/0ad4d3fe6c6251318e443264c1d46189/ba381/logo-ihk.webp 200w", "/static/0ad4d3fe6c6251318e443264c1d46189/8ae5a/logo-ihk.webp 340w", "/static/0ad4d3fe6c6251318e443264c1d46189/002e6/logo-ihk.webp 520w", "/static/0ad4d3fe6c6251318e443264c1d46189/e88ff/logo-ihk.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0ad4d3fe6c6251318e443264c1d46189/69538/logo-ihk.png 160w", "/static/0ad4d3fe6c6251318e443264c1d46189/772e8/logo-ihk.png 200w", "/static/0ad4d3fe6c6251318e443264c1d46189/9f933/logo-ihk.png 340w", "/static/0ad4d3fe6c6251318e443264c1d46189/69902/logo-ihk.png 520w", "/static/0ad4d3fe6c6251318e443264c1d46189/0a47e/logo-ihk.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0ad4d3fe6c6251318e443264c1d46189/0a47e/logo-ihk.png",
              "alt": "Logo IHK",
              "title": "Logo IHK",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`IHK`}</h3>
      <p>{`Industrie- und Handelskammer zu Berlin`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.ihk-berlin.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26498562dbb11773b189b600cf21ea5c/81b0c/logo-aussergewoehnlich-berlin.avif 160w", "/static/26498562dbb11773b189b600cf21ea5c/f13a9/logo-aussergewoehnlich-berlin.avif 200w", "/static/26498562dbb11773b189b600cf21ea5c/5f1e1/logo-aussergewoehnlich-berlin.avif 340w", "/static/26498562dbb11773b189b600cf21ea5c/3c285/logo-aussergewoehnlich-berlin.avif 520w", "/static/26498562dbb11773b189b600cf21ea5c/56a33/logo-aussergewoehnlich-berlin.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/26498562dbb11773b189b600cf21ea5c/60a22/logo-aussergewoehnlich-berlin.webp 160w", "/static/26498562dbb11773b189b600cf21ea5c/ba381/logo-aussergewoehnlich-berlin.webp 200w", "/static/26498562dbb11773b189b600cf21ea5c/8ae5a/logo-aussergewoehnlich-berlin.webp 340w", "/static/26498562dbb11773b189b600cf21ea5c/002e6/logo-aussergewoehnlich-berlin.webp 520w", "/static/26498562dbb11773b189b600cf21ea5c/e88ff/logo-aussergewoehnlich-berlin.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26498562dbb11773b189b600cf21ea5c/69538/logo-aussergewoehnlich-berlin.png 160w", "/static/26498562dbb11773b189b600cf21ea5c/772e8/logo-aussergewoehnlich-berlin.png 200w", "/static/26498562dbb11773b189b600cf21ea5c/9f933/logo-aussergewoehnlich-berlin.png 340w", "/static/26498562dbb11773b189b600cf21ea5c/69902/logo-aussergewoehnlich-berlin.png 520w", "/static/26498562dbb11773b189b600cf21ea5c/0a47e/logo-aussergewoehnlich-berlin.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26498562dbb11773b189b600cf21ea5c/0a47e/logo-aussergewoehnlich-berlin.png",
              "alt": "Logo Außergewöhnlich Berlin",
              "title": "Logo Außergewöhnlich Berlin",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Außergewöhnlich Berlin`}</h3>
      <p>{`Berlins Club für Kooperationen, Inspirationen und Berliner Werte`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.aussergewoehnlich-berlin.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    <PartnerBox mdxType="PartnerBox">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "680px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.49999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d59a4cea95bb4338b97a151f54ba1569/81b0c/logo-green-software.avif 160w", "/static/d59a4cea95bb4338b97a151f54ba1569/f13a9/logo-green-software.avif 200w", "/static/d59a4cea95bb4338b97a151f54ba1569/5f1e1/logo-green-software.avif 340w", "/static/d59a4cea95bb4338b97a151f54ba1569/3c285/logo-green-software.avif 520w", "/static/d59a4cea95bb4338b97a151f54ba1569/e3b5b/logo-green-software.avif 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/static/d59a4cea95bb4338b97a151f54ba1569/60a22/logo-green-software.webp 160w", "/static/d59a4cea95bb4338b97a151f54ba1569/ba381/logo-green-software.webp 200w", "/static/d59a4cea95bb4338b97a151f54ba1569/8ae5a/logo-green-software.webp 340w", "/static/d59a4cea95bb4338b97a151f54ba1569/002e6/logo-green-software.webp 520w", "/static/d59a4cea95bb4338b97a151f54ba1569/35037/logo-green-software.webp 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d59a4cea95bb4338b97a151f54ba1569/69538/logo-green-software.png 160w", "/static/d59a4cea95bb4338b97a151f54ba1569/772e8/logo-green-software.png 200w", "/static/d59a4cea95bb4338b97a151f54ba1569/9f933/logo-green-software.png 340w", "/static/d59a4cea95bb4338b97a151f54ba1569/69902/logo-green-software.png 520w", "/static/d59a4cea95bb4338b97a151f54ba1569/c5bb3/logo-green-software.png 680w"],
              "sizes": "(max-width: 680px) 100vw, 680px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d59a4cea95bb4338b97a151f54ba1569/c5bb3/logo-green-software.png",
              "alt": "Logo Green Software",
              "title": "Logo Green Software",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <h3>{`Bundesverband Green Software`}</h3>
      <p>{`Gründungsmitglied des Bundesverband Green Software`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.bundesverband-green-software.de/"
        }}>{`Website des Partners`}</a></p>
    </PartnerBox>
    {/* muss hier stehen bleiben */}
    <h2></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      